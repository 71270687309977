// src/components/Login.js
import React from 'react';
import { signInWithPopup } from 'firebase/auth';
import { auth, googleProvider, db, storage } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { ref, uploadString } from "firebase/storage";

function Login() {
  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      
      // Verificar si el usuario ya existe en Firestore
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      
      if (!userDoc.exists()) {
        // Si el usuario no existe, crear un nuevo documento
        await setDoc(doc(db, 'users', user.uid), {
          displayName: user.displayName,
          sendEmailAddress: user.email,
          signInProvider: 1,
          userLocale: navigator.language || 'en',
          appInterfaceLanguageLocale: navigator.language || 'en',
          hintKeywords: [],
          aboutMe: '',
        });

        // Guardar la foto del usuario en Storage
        if (user.photoURL) {
          try {
            const response = await fetch(user.photoURL);
            const blob = await response.blob();
            const reader = new FileReader();
            reader.onloadend = async () => {
              const base64data = reader.result;
              const storageRef = ref(storage, `userPictures/${user.uid}.jpg`);
              await uploadString(storageRef, base64data, 'data_url');
            }
            reader.readAsDataURL(blob);
          } catch (error) {
            console.error("Error al guardar la foto del usuario:", error);
          }
        }
      }
      
      // El usuario ha iniciado sesión exitosamente
    } catch (error) {
      console.error("Error al iniciar sesión con Google:", error);
    }
  };

  return (
    <div className="login-container">
      <h1>Bienvenido a NoteMail</h1>
      <button onClick={signInWithGoogle}>Iniciar sesión con Google</button>
    </div>
  );
}

export default Login;