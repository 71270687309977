// src/components/UserProfile.js
import React, { useEffect, useState } from 'react';
import { auth, db, storage } from '../firebase';
import { doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';

function UserProfile() {
  const [userData, setUserData] = useState(null);
  const [profilePicUrl, setProfilePicUrl] = useState(null);

  // States for adding keywords
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newKeyword, setNewKeyword] = useState('');

  // Editable fields
  const [aboutMe, setAboutMe] = useState('');
  const [appLanguage, setAppLanguage] = useState('EN'); // Default value
  const [summarizationLanguage, setSummarizationLanguage] = useState('EN'); // Default value
  const [hintKeywords, setHintKeywords] = useState([]);

  // State to track if any changes have been made
  const [isDirty, setIsDirty] = useState(false);

  // Mapping for signInProvider
  const signInProviderMap = {
    0: 'Email',
    1: 'Google',
    2: 'Apple',
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          setUserData(data);
          setAboutMe(data.aboutMe || '');
          setAppLanguage(data.appInterfaceLanguageLocale ? data.appInterfaceLanguageLocale.toUpperCase() : 'EN');
          setSummarizationLanguage(data.userLocale ? data.userLocale.toUpperCase() : 'EN');
          setHintKeywords(data.hintKeywords || []);
        } else {
          // If the document does not exist, initialize with default values
          setUserData({
            appInterfaceLanguageLocale: 'EN',
            userLocale: 'EN',
            aboutMe: '',
            hintKeywords: [],
          });
          setAboutMe('');
          setAppLanguage('EN');
          setSummarizationLanguage('EN');
          setHintKeywords([]);
        }

        try {
          const url = await getDownloadURL(ref(storage, `userPictures/${auth.currentUser.uid}.jpg`));
          setProfilePicUrl(url);
        } catch (error) {
          console.error("Error fetching profile picture:", error);
        }
      }
    };
    fetchUserData();
  }, []);

  if (!userData) {
    return <div>Loading user data...</div>;
  }

  const userDocRef = doc(db, 'users', auth.currentUser.uid);

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setNewKeyword('');
  };

  // Function to add a new keyword to local state
  const addKeyword = () => {
    if (newKeyword.trim() === '') return;
    setHintKeywords(prevKeywords => [...prevKeywords, newKeyword.trim()]);
    setNewKeyword('');
    setIsDirty(true);
    closeModal();
  };

  // Function to remove a keyword from local state
  const removeKeyword = (index) => {
    setHintKeywords(prevKeywords => prevKeywords.filter((_, i) => i !== index));
    setIsDirty(true);
  };

  // Function to handle saving all changes to Firestore
  const handleSave = async () => {
    try {
      await updateDoc(userDocRef, {
        aboutMe: aboutMe,
        appInterfaceLanguageLocale: appLanguage,
        userLocale: summarizationLanguage,
        hintKeywords: hintKeywords,
      });
      alert("Profile updated successfully.");
      setIsDirty(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("There was an error updating your profile.");
    }
  };

  const UserAvatar = () => {
    if (profilePicUrl) {
      return <img src={profilePicUrl} alt="User" className="w-20 h-20 rounded-full object-cover" />;
    } else {
      const initials = userData.displayName ? userData.displayName.charAt(0).toUpperCase() : '?';
      return (
        <div className="w-20 h-20 rounded-full bg-gray-300 flex items-center justify-center text-gray-600 text-2xl font-bold">
          {initials}
        </div>
      );
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6 max-w-xl mx-auto mt-10">
      <div className="flex flex-col sm:flex-row items-center mb-6">
        <UserAvatar />
        <div className="mt-4 sm:mt-0 sm:ml-4 text-center sm:text-left">
          <h2 className="text-2xl font-bold">{userData.displayName || 'Username'}</h2>
          <p className="text-gray-500">{auth.currentUser.uid}</p>
        </div>
        <p className="mt-4 sm:mt-0 sm:ml-auto text-gray-500">
          {signInProviderMap[userData.signInProvider] || 'Unknown'}
        </p>
      </div>
      <hr className="my-6" />
      <div className="space-y-4">
        <div>
          <p className="text-gray-600">Email:</p>
          <p>{userData.sendEmailAddress || auth.currentUser.email}</p>
        </div>
        <div>
          <p className="text-gray-600 flex items-center">Keywords:</p>
          <div className="flex flex-wrap gap-2">
            {hintKeywords.map((keyword, index) => (
              <span key={index} className="bg-blue-100 text-blue-800 px-2 py-1 rounded flex items-center">
                {keyword}
                <button
                  onClick={() => removeKeyword(index)}
                  className="ml-1 text-red-500 hover:text-red-700"
                  title="Remove Keyword"
                >
                  &times;
                </button>
              </span>
            ))}
            <button
              onClick={openModal}
              className="bg-gray-300 text-black font-bold px-3 py-1 rounded-full w-8 h-8 flex items-center justify-center"
              title="Add Keyword"
            >
              +
            </button>
          </div>
        </div>
        <div>
          <p className="text-gray-600">App Language:</p>
          <select
            value={appLanguage}
            onChange={(e) => { setAppLanguage(e.target.value); setIsDirty(true); }}
            className="border rounded p-1 w-full"
            required
          >
            <option value="EN">English</option>
            <option value="ES">Spanish</option>
            <option value="IT">Italian</option>
          </select>
        </div>
        <div>
          <p className="text-gray-600">Summarization Language:</p>
          <select
            value={summarizationLanguage}
            onChange={(e) => { setSummarizationLanguage(e.target.value); setIsDirty(true); }}
            className="border rounded p-1 w-full"
            required
          >
            <option value="EN">English (EN)</option>
            <option value="ES">Spanish (ES)</option>
            <option value="IT">Italian (IT)</option>
            <option value="PO">Portuguese (PO)</option>
            <option value="DE">German (DE)</option>
            <option value="FR">French (FR)</option>
            <option value="CAT">Catalan (CAT)</option>
          </select>
        </div>
        <div>
          <p className="text-gray-600">About Me:</p>
          <textarea 
            value={aboutMe} 
            onChange={(e) => { setAboutMe(e.target.value); setIsDirty(true); }} 
            className="border rounded p-1 w-full h-24"
          />
        </div>
      </div>
      <button
        onClick={handleSave}
        className={`mt-4 bg-blue-500 text-white px-4 py-2 rounded ${isDirty ? 'opacity-100 cursor-pointer' : 'opacity-50 cursor-not-allowed'}`}
        disabled={!isDirty}
      >
        Save
      </button>

      {/* Modal for adding keyword */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-80">
            <h3 className="text-xl font-bold mb-4">Add Keyword</h3>
            <input
              type="text"
              value={newKeyword}
              onChange={(e) => setNewKeyword(e.target.value)}
              className="border rounded p-2 w-full mb-4"
              placeholder="New keyword"
            />
            <div className="flex justify-end space-x-2">
              <button 
                onClick={closeModal}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
              >
                Cancel
              </button>
              <button 
                onClick={addKeyword}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserProfile;