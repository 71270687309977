import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import '../App.css';

function NotePlayerPublicLink() {
  const [searchParams] = useSearchParams();
  const [note, setNote] = useState(null);

  useEffect(() => {
    const encodedData = searchParams.get('data');
    if (encodedData) {
      try {
        let base64String = encodedData.replace(/-/g, '+').replace(/_/g, '/');
        const paddingNeeded = 4 - (base64String.length % 4);
        if (paddingNeeded !== 4) {
          base64String += '='.repeat(paddingNeeded);
        }
        const decodedData = JSON.parse(decodeURIComponent(escape(atob(base64String))));
        setNote(decodedData);
      } catch (error) {
        console.error("Error decoding note data:", error);
      }
    } else {
      console.warn("No 'data' parameter found in the URL.");
    }
  }, [searchParams]);

  if (!note) {
    return <p>Cargando...</p>;
  }

  return (
    <div className="note-player">
      <div className="note-emoji">{note.emoji}</div>
      <h1>{note.title}</h1>
      <ReactMarkdown remarkPlugins={[remarkGfm]} className="summary">
        {note.summary}
      </ReactMarkdown>
      {note.audioUrl ? (
        <div className="audio-player">
          <audio controls src={note.audioUrl}>
            Tu navegador no soporta el elemento de audio.
          </audio>
        </div>
      ) : (
        <p>No se proporcionó una URL de audio válida.</p>
      )}
      <div className="footer">
        <img 
          src="https://firebasestorage.googleapis.com/v0/b/email2me-52ae4.appspot.com/o/publicResources%2Femails%2FiconSmall.png?alt=media&token=3c41bddc-977f-402e-8404-0bb2121e1f16" 
          width="15" 
          alt="NoteMail Logo" 
        />
        Done with <strong><a href="https://www.notemail.ai/" target="_blank" rel="noopener noreferrer">NoteMail</a></strong>
      </div>
    </div>
  );
}

export default NotePlayerPublicLink;