// App.js
import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import Login from './components/Login';
import UserProfile from './components/UserProfile';
import NotePlayerPublicLink from './components/NotePlayerPublicLink';
import Header from './components/Header';
import './App.css';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen bg-[#F8FAFB] flex items-center justify-center">
        <div className="text-gray-400 text-base">Loading...</div>
      </div>
    );
  }
  return (
    <div className="app-container min-h-screen bg-[#F8FAFB]">
      {user && <Header user={user} />}
      <main className="app-main w-full px-4 sm:px-6 lg:px-8 py-6">
        <div className="max-w-7xl mx-auto">
          <Routes>
            <Route path="/login" element={user ? <Navigate to="/profile" /> : <Login />} />
            <Route
              path="/profile"
              element={user ? <UserProfile user={user} /> : <Navigate to="/login" />}
            />
            <Route path="/voicenotes_public_link" element={<NotePlayerPublicLink />} />
            <Route path="/" element={<Navigate to="/profile" />} />
          </Routes>
        </div>
      </main>
    </div>
  );
}

export default App;