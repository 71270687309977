// src/components/Header.js
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import { auth, storage } from '../firebase';
import { ref, getDownloadURL } from 'firebase/storage';
import logo from './notemail_logo.png';

function Header({ user }) {
  const [profilePicUrl, setProfilePicUrl] = useState(null);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    if (user) {
      getDownloadURL(ref(storage, `userPictures/${user.uid}.jpg`))
        .then(setProfilePicUrl)
        .catch(console.error);
    }
  }, [user]);

  const UserAvatar = () => {
    if (profilePicUrl) {
      return <img src={profilePicUrl} alt="User" className="w-8 h-8 rounded-full" />;
    } else {
      const initials = user.displayName ? user.displayName.charAt(0).toUpperCase() : '?';
      return (
        <div className="w-8 h-8 rounded-full bg-purple-500 flex items-center justify-center text-white">
          {initials}
        </div>
      );
    }
  };

  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex-shrink-0">
            <img src={logo} alt="NoteMail Logo" className="h-10 w-auto" />
          </div>

          <div className="flex items-center">
            {!isMobile && (
              <nav className="hidden md:flex space-x-4 mr-4">
                <Link
                  to="/notes"
                  className="text-gray-700 hover:bg-gray-200 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                >
                  Notas
                </Link>
                {/* Agrega más enlaces de navegación aquí si es necesario */}
              </nav>
            )}
            <Menu as="div" className="ml-3 relative">
              <div>
                <Menu.Button className="flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <UserAvatar />
                  <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </Menu.Button>
              </div>
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/profile"
                        className={`${
                          active ? 'bg-gray-100' : ''
                        } block px-4 py-2 text-sm text-gray-700`}
                      >
                        Tu perfil
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => auth.signOut()}
                        className={`${
                          active ? 'bg-gray-100' : ''
                        } block w-full text-left px-4 py-2 text-sm text-gray-700`}
                      >
                        Cerrar sesión
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Menu>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;